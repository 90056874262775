var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"6f1a314a85dd8f506686c06f2235ca570e7f9624"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from '@sentry/nextjs';
import { excludeGraphQLFetch } from 'apollo-link-sentry';
import { IS_DEV, IS_PRODUCTION, IS_PREVIEW, IS_STAGING } from './src/constants';
import { getEnv } from './src/utils/sentry';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  debug: false,
  environment: getEnv(),

  dsn: SENTRY_DSN || 'https://1819afd354284f66b534e69bbdfe7f5d@o507761.ingest.sentry.io/5599296',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1.0,
  normalizeDepth: 10,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: IS_PRODUCTION || IS_STAGING ? 1.0 : 0,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: IS_PRODUCTION || IS_STAGING ? 1.0 : 0,

  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  beforeSend(event, hint) {
    console.error('SENTRY beforeSend', hint);

    if (IS_DEV) {
      console.error('SENTRY beforeSend', hint?.originalException || hint?.syntheticException);
      return null;
    }

    if (event.message?.includes('Not authorized to')) {
      // Skip unauth
      return null;
    }

    return event;
  },
  integrations: [
    Sentry.browserTracingIntegration({
      // idleTimeout: 1,
      tracingOrigins: [
        '0.0.0.0:8001',
        'localhost',
        'https://staging.shepherd.insure',
        'https://canary-api.shepherd.insure',
        'https://api.shepherd.insure',
        /^\//,
      ],
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      // maskAttributes: false,
    }),
    new Sentry.BrowserProfilingIntegration(),
  ],
  release: process.env.NEXT_PUBLIC_COMMIT_SHA,
  // Comment out for now...
  beforeBreadcrumb: excludeGraphQLFetch,
  initialScope: {
    tags: {
      git_comment_ref: process.env.VERCEL_GIT_COMMIT_REF || process.env.VERCEL_GIT_COMMIT_REF,
      vercel_env: process.env.VERCEL_ENV || process.env.NEXT_PUBLIC_VERCEL_ENV,
    },
  },
});
